$nav-sticky-background-color: var(--color-white);

$nav-link-color: var(--color-white);
$nav-link-color-active: var(--color-primary);
$nav-link-color-hover: var(--color-primary);
$nav-link-font-size: 20px;
$nav-link-font-size-large: 28px;
$nav-menu-border-radius: 2px;

$nav-menu-background-color: var(--color-white);
$nav-menu-link-color: var(--color-primary);
$nav-menu-background-hover-color: var(--color-primary);
$nav-menu-link-hover-color: var(--color-white);

$nav-sticky-link-color: var(--color-primary);
$nav-sticky-link-color-active: var(--color-primary);
$nav-sticky-link-hover-color: var(--color-primary);

$nav-mobile-link-color: var(--color-primary);
$nav-mobile-link-hover-color: var(--color-primary-80);

/*
$nav-link-color: $color-primary;
$nav-link-color-active: $color-primary-60;
$nav-link-color-hover: $color-primary-60;

$nav-menu-background-color: $color-primary-80;
$nav-menu-background-hover-color: #27763C;

$nav-menu-link-color: #fff;
$nav-menu-link-hover-color: #fff;
 */
/*
body {
  font-family: $font-family-sans-serif;
  background-color: #fff;

  height: 200vh;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .5);
    transition: .3s all ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }
  &.offcanvas-menu {
    &:before {
      opacity: 1;
      z-index: 1002;
      visibility: visible;
    }
  }
}
p {
  color: darken(#ccc, 10%);
  font-weight: 300;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family-sans-serif;
}
a {
  transition: .3s all ease;
  &, &:hover {
    text-decoration: none!important;
  }
}
*/


/*
.hero {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
*/

/*
.site-navbar > .container > .row {
  margin-top: -15px;

  @media all and (max-width: 991px) {
    margin-top: 0;
  }
  @media all and (max-width: 767px) {
    margin-top: 0;
  }
}
*/


.site-navbar {
  margin-top: -25px;

  @media all and (max-width: 991px) {
    margin-top: 0;

    .site-navbar-container {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  @media all and (max-width: 767px) {
    margin-top: 0;
  }
}

.site-menu {
  // margin-top: 25px;
}

.sticky-wrapper {
  height: auto !important;
  // margin-bottom: -25px;
}

#headerlogo {
  max-width: 150px;
}

.sticky-wrapper.is-sticky #headerlogo,
.sticky-wrapper.is-sticky #headerlogoInverse {
  height: 40px !important;
}

.sticky-wrapper #headerlogo {
  // width: 300px;
  // max-width: 300px;

  // width: 120px;
  // max-width: 120px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
  // height: 80px;

  transition: all 0.4s;
  // margin-top: -25px;

  // @media all and (max-width: 991px) {
    width: 120px;
    height: 87px;
    max-width: 120px;
    max-height: 87px;
    // margin-top: 1rem;
  // }

  @media all and (min-width: 992px) {
    width: 150px !important;
    height: 109px !important;
    max-width: 150px !important;
    max-height: 109px !important;
    // margin-left: 0.25rem;
    // margin-top: 1rem;
  }
}

.sticky-wrapper .col-nav {
  @media all and (min-width: 992px) {
    // align-self: center;
  }
}

.logo-wrapper {
  max-width: 120px;
  @media all and (min-width: 992px) {
    max-width: 150px;
  }
}

.sticky-wrapper .toggle-button {
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
}

.sticky-wrapper .col-nav {
  @media all and (max-width: 991px) {
    // align-self: center;
  }
}

.sticky-wrapper #headerlogoInverse {
  // width: 300px;
  // max-width: 300px;

  height: 80px;

  transition: all 0.4s;
  // margin-top: -25px;

  @media all and (max-width: 991px) {
    max-width: 200px !important;
    margin-top: 0;
  }
  @media all and (max-width: 767px) {
    max-width: 200px !important;
    margin-top: 0;
  }
}
.sticky-wrapper #headerlogo {
  display: block;
}

.sticky-wrapper #headerlogoInverse {
  display: none;
}

.sticky-wrapper.is-sticky #headerlogo,
.sticky-wrapper.is-sticky #headerlogoInverse {
  width: 250px;
  max-width: 250px;

  @media all and (max-width: 767px) {
    width: 180px;
    max-width: 180px;
    max-width: 180px;
  }

  margin-top: 0;
  transition: all 0.4s;
  // transform: scale(1.5) rotate(0.01deg);
}

.sticky-wrapper.is-sticky #headerlogo {
  margin-top: 25px !important;
  display: none !important;
}

.sticky-wrapper.is-sticky #headerlogoInverse {
  margin-top: 25px;
  @media all and (max-width: 991px) {
    margin-top: 0;
  }
  @media all and (max-width: 767px) {
    margin-top: 0;
  }
  display: block !important;
}

.site-navbar-wrap {
  position: absolute;
  z-index: 1001;
  width: 100%;
  left: 0;
  a {
    color: $nav-link-color;
  }

  .site-navbar-top {
    font-size: $font-size-base - .2rem;
    background-color: $white;
    height: 32px;
  }
  .site-navbar-top.navbar-overlay {
    background-color: transparent;
  }
}


.site-navigation {
  margin-top: 38px;

  .coin-wrapper-mobile {
    display: none;
  }

  @media all and (max-width: 991px) {
    margin-top: 0;

    .coin-wrapper-mobile {
      display: flex;
      position:absolute;
      right: 70px;
      top: 20px;
      align-items: baseline;

      .coin-number {
        align-self: end;
      }
    }
  }
  @media all and (max-width: 767px) {
    margin-top: 0;
  }
}

.site-navbar-top {
  // border-bottom: 1px solid rgba($black, .4);
}

a.site-menu-toggle {
  text-decoration: none;
  color: $color-white;
  &.active, &:hover {
    color: $color-white;
  }
}

.toggle-button {
  font-size: 2.5rem;
}




@media (min-width: 992px) {
  .nav-menu-columns--2 {
    columns: 2;
  }

  .nav-menu-columns--3 {
    columns: 3;
  }

  .nav-menu-columns--4 {
    columns: 4;
  }

  .nav-menu-align--right {
    right: -100px;
  }
}


.site-navbar {
  margin-bottom: 0px;
  width: 100%;
  // border-bottom: 1px solid rgba($white, .1);

  .site-logo {
    font-weight: 200;
    line-height: 0;
    top: -7px;
    position: relative;
    a {
      font-weight: 200;
      color: $nav-link-color;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .site-navigation {

    .coin-number {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }

    .site-menu {
      margin-bottom: 0;

      a {
        text-transform: uppercase;
        text-decoration: none!important;
        display: inline-block;
        font-weight: 400;
      }

      > li {
        display: inline-block;
        padding-bottom: 5px;
        // padding: 4px 5px;
        > a {

          border-bottom: 3px solid transparent; /* <- here */
          transition: border-bottom 0.5s;
          text-decoration: none;

          padding: 0 5px;
          margin: 0 10px;
          // padding: 0 0 0 10px;
          color: $nav-link-color;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
          // color: rgba($nav-link-color, .6);
          font-family: $font-navigation;
          font-size: $nav-link-font-size;
          @media (min-width: 1200px) {
            font-size: $nav-link-font-size-large;
          }
          text-decoration: none !important;
          &.active {
            color: $nav-link-color-active;
          }
          &:hover {
            // #nav13
            // color: $nav-link-color-hover;
            border-bottom: 2px solid white;
            box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
          }
          &.nav-current {
            border-bottom: 2px solid white;
            box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
          }
        }
        .hide-home-link {
          display: none;
        }
        &:last-child {
          // padding-right: 0;
          > a {
            // padding-right: 0;
          }
        }
      }
      .has-children {
        position: relative;
        > a {
          position: relative;
          padding-right: 30px;
          &:before {
            position: absolute;
            content: "\e313";
            font-size: 16px;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            font-family: 'icomoon';
          }
        }
        .dropdown {
          visibility: hidden;
          opacity: 0;
          top: 100%;
          position: absolute;
          text-align: left;
          box-shadow: 0 0px 4px 0px rgba(0,0,0,.25);
          padding: 0px 0;
          // Hover Animation
          margin-top: 20px;
          transition: margin-top 0.2s 0s;
          margin-left: 0px;
          background: $nav-menu-background-color;
          border-radius: $nav-menu-border-radius;
          /*
                    &.arrow-top {
                      width: 0;
                      height: 0;
                      border-top: 22px solid transparent;
                      border-bottom: 22px solid transparent;
                      border-right: 22px solid black;
                      position: absolute;
                    }

                    &.arrow-top:before {
                      content: '';
                      width: 0;
                      height: 0;
                      border-top: 21px solid transparent;
                      border-bottom: 21px solid transparent;
                      border-right: 21px solid #dddddd;
                      position: absolute;
                      top: -21px;
                      left: 1px;
                    }
          */
          /*
                    &.arrow-top {
                      position: absolute;
                      // overflow: hidden;
                      box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
                    }
                    &.arrow-top:after {
                      content: "";
                      position: absolute;
                      width: 50px;
                      height: 50px;
                      background: #999;
                      transform: rotate(45deg);
                      top: 0;
                      left: 25px;
                      box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
                    }
          */

          &.arrow-top {
            position: absolute;
            &:before {
              bottom: 100%;
              left: 20%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }
            /*
            &:before {
              border-color: rgba(136, 183, 213, 0);
              border-bottom-color: $nav-menu-background-color;
              border-width: 10px;
              margin-left: -10px;
              filter: drop-shadow(0 0 0 rgba(0,0,0,0.5));
            }
             */
          }

          a {
            font-size: 18px;
            text-transform: none;
            letter-spacing: normal;
            transition: 0s all;
            color: $nav-menu-link-color;
            &.active {
              color: $primary;
            }
          }
          .active {
            > a {
              color: $white !important;
            }
          }
          > li {
            display: inline-block;
            list-style: none;
            padding: 0;
            margin: 0;
            min-width: 250px;
            &:first-child {
              > a {
                border-top-left-radius: $nav-menu-border-radius;
                border-top-right-radius: $nav-menu-border-radius;
                color: $nav-menu-link-color;
              }
            }
            &:last-child {
              > a {
                border-bottom-left-radius: $nav-menu-border-radius;
                border-bottom-right-radius: $nav-menu-border-radius;
              }
            }
            > a {
              padding: 9px 20px;
              display: block;
              &:hover {
                background: $nav-menu-background-hover-color;
                // background: lighten($gray-400, 10%);
                color: $nav-menu-link-hover-color;
              }
            }

            &.has-children {
              > a {
                &:before {
                  content: "\e315";
                  right: 20px;
                }
              }
              > .dropdown, > ul {
                left: 100%;
                top: 0;
              }
              &:hover, &:active, &:focus {
                > a {
                  background: $nav-menu-background-hover-color;
                  color: $nav-menu-link-color;
                }
              }
            }
          }
        }

        &:hover, &:focus, &:active {
          > a {
            // color: $nav-link-color-hover;
          }
        }
        &:hover, &:focus, &:active {
          cursor: pointer;
          > .dropdown {
            transition-delay: 0s;
            margin-top: 0px;
            visibility: visible;
            opacity: 0.95;
            z-index: 1;

          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .site-navbar .site-navigation .site-menu.desktop-no-submenus .has-children > a {
    padding-right: 10px;

    + ul.dropdown {
      display: none;
    }

    &:before {
      content: "";
    }
  }
}

main:not(.navbar-overlay) {
  padding-top: 120px;
}


// mobile menu

body.offcanvas-menu {
  overflow: hidden;

  .coin-wrapper {
    display: none;
  }

}
.site-mobile-menu {
  width: 320px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 15px;
  background: $white;
  height: calc(100vh);

  transform: translateX(110%);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.5);
  transition: .3s all ease-in-out;

  .hide-home-link {
    display: block;
  }

  .offcanvas-menu & {
    transform: translateX(0%);
  }

  .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;

    .site-mobile-menu-close {
      float: right;
      // margin-top: 0.5rem;
      margin-top: -2px;
      margin-right: -0.5rem;
      span {
        font-size: 2.5rem;
        display: inline-block;
        padding-left: 10px;
        padding-right: 0px;
        line-height: 1;
        cursor: pointer;
        transition: .3s all ease;
        &:hover {
          color: $color-primary-60;
        }
      }
    }
    .site-mobile-menu-logo {
      float: left;
      margin-top: 10px;
      margin-left: 0;
      a {
        display: inline-block;
        text-transform: uppercase;
        img {
          max-width: 70px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .site-mobile-menu-body {
    // height: 400px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 20px;

    height: calc(100vh - 52px);
    padding-bottom: 150px;

  }
  .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    // float: left;

    // width: 100%;
    // height: 100%;
    position: relative;

    a {
      padding: 10px 20px;
      display: block;
      position: relative;
      color: $nav-mobile-link-color;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700;
      &.active, &:hover {
        color: $nav-mobile-link-hover-color;
      }
    }
    li {
      position: relative;
      display: block;
      &.active {
        > a {
          color: $nav-mobile-link-color;
          &:hover {
            color: $nav-mobile-link-hover-color;
          }
        }
      }
    }
    .arrow-collapse {
      position: absolute;
      right: 0px;
      top: 5px;
      z-index: 20;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        background: $gray-100;
      }
      &:before {
        font-size: 12px;
        z-index: 20;
        font-family: "icomoon";
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-180deg);
        transition: .3s all ease;
      }
      &.collapsed {
        &:before {
          transform: translate(-50%, -50%);
        }
      }
    }
    > li {
      display: block;
      position: relative;
      float: left;
      width: 100%;

      > a {
        padding-left: 20px;
        font-size: 20px;
      }
      > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        > li {
          display: block;
          > a {
            font-weight: 400;
            padding-left: 40px;
            font-size: 16px;
          }
          > ul {
            padding: 0;
            margin: 0;
            > li {
              display: block;
              > a {
                font-size: 16px;
                padding-left: 60px;
              }
            }
          }
        }
      }
    }

  }
}

.sticky-wrapper {
  background-color: $white;
  position: absolute;
  z-index: 100;
  width: 100%;

  // height: 75px !important;
  & + .site-blocks-cover {
    // margin-top: 140px;
    margin-top: 96px;
  }
  .site-navbar {

    transition: .3s all ease;
    ul {
      li {
        &.active {
          a {
            color: $nav-link-color-active;
          }
        }
      }
    }

  }

  &.is-sticky {

    .toggle-button {
      color: $color-primary;
      filter: none;
    }

    .coin-number {
      text-shadow: none;
    }

    .site-navbar {
      background-color: $nav-sticky-background-color;
      border-bottom: 1px solid #e0e0e0;
      // box-shadow: 0 4px 4px -4px #e0e0e0;
      box-shadow: 0 5px 40px rgba(10, 10, 10, 0.4);


      .site-navigation {
        .site-menu {
          > li {
            /*
            display: inline-block;
            padding: 0 0 10px;
            font-size: 18px;
            &:last-child {
              padding-right: 0;
              > a {
                padding-right: 0;
              }
            }
            &.has-children {
              > a {
                padding-right: 20px;
              }
            }
             */
            > a {

              color: $nav-sticky-link-color;
              text-shadow: none;

              border-bottom: 2px solid transparent; /* <- here */
              box-shadow: none;

              transition: border-bottom 0.5s;
              text-decoration: none;

              /*
              padding: 0 10px;
              font-size: 18px;
               */
              &:hover {
                color: $nav-sticky-link-hover-color;
                border-bottom: 3px solid $nav-sticky-link-hover-color;
              }
              &.active {
                color: $nav-sticky-link-color-active;
              }
              &.nav-current {
                border-bottom: 3px solid $nav-sticky-link-color;
              }
            }
            &.active {
              a {
                color: $white;
              }
            }
          }
        }
      }


    }
  }


  .shrink {
    // padding-top: 10px!important;
    // padding-bottom: 10px!important;
  }
}

.navbar-overlay + .sticky-wrapper {
  background-color: transparent;
}


ul.global-links {
  margin: 0 0 -10px 20px;
  padding: 0;
  display: inline-block;

  li {
    list-style-type: none;
    display: inline;

    /*
    &:not(:first-child):before {
      content: "|";
    }
    */

    a {
      font-size: 0.875rem;
      @media (min-width: 1200px) {
        font-size: 1rem;
      }
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      z-index: 1000;
    }
  }

  > li:first-child {
    margin-left: 20px;
  }
}

ul.global-links-mobile {
  margin-top: 10px;
  padding-left: 20px;
  display: inline-block;

  li {
    list-style-type: none;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: $nav-mobile-link-color;
      &:hover {
        color: $nav-mobile-link-hover-color;
      }
    }
  }
}


@media all and (max-width: 991px) {

  main:not(.navbar-overlay) {
    padding-top: 80px;
  }

  .site-navbar-top {
    display: none;
  }
}
