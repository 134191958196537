body {
  font-family: $font-default;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;

  @media (max-width: 991px) {
    font-size: 1.375rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }

  color: $color-text;
  overflow: auto;
}

/*------------------------------------------------------------------------*/
/* Forms                                                                  */
/*------------------------------------------------------------------------*/
/*
$char-w: 1ch;
$gap: .5*$char-w;
$n-char: 4;
$in-w: $n-char*($char-w + $gap);

input[type="number"] {
  display: block;
  margin: 2em auto;
  border: none;
  padding: 0;
  width: $in-w;
  background: repeating-linear-gradient(90deg,
          dimgrey 0, dimgrey $char-w,
          transparent 0, transparent $char-w + $gap)
  0 100%/ #{$in-w - $gap} 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: $gap;

  &:focus {
    outline: none;
    color: black;
  }
}
*/

/*
input[type="number"] {
  border: solid 1px dimgrey;
  width: 400px;
  background: repeating-linear-gradient(90deg, #ffffff 0px, #ffffff 19px, #000000 20px);
  color: dimgrey;
  font-family: monospace;
  letter-spacing: 1.75ch;
  padding-left: 0.8ch;
}

input:focus {
  outline: none;
  color: dodgerblue;
}
*/

/*------------------------------------------------------------------------*/
/* Fonts                                                                  */
/*------------------------------------------------------------------------*/

.display-6 {
  @media (max-width: 991px) {
    font-size: calc(1.25rem + 1.5vw);
  }
  @media (max-width: 767px) {
    font-size: calc(1rem + 1.5vw);
  }
}

/*
.leaflet-touch .leaflet-bar button {
  background-color: red;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
*/

.leaflet-touch .leaflet-bar button  {
  padding: 0;
}

/*------------------------------------------------------------------------*/
/* Custom Widths                                                          */
/*------------------------------------------------------------------------*/
/* SM breakpoint */
@media (min-width: 576px) {
  /* CUSTOM WIDTHS */
  .w-sm-auto { width: auto !important; }
  .w-sm-10 { width: 10% !important; }
  .w-sm-20 { width: 20% !important; }
  .w-sm-25 { width: 25% !important; }
  .w-sm-50 { width: 50% !important; }
  .w-sm-75 { width: 75% !important; }
  .w-sm-100 { width: 100% !important; }
}

/* MD breakpoint*/
@media (min-width: 768px) {
  /* CUSTOM WIDTHS */
  .w-md-auto { width: auto !important; }
  .w-md-10 { width: 10% !important; }
  .w-md-20 { width: 20% !important; }
  .w-md-25 { width: 25% !important; }
  .w-md-50 { width: 50% !important; }
  .w-md-75 { width: 75% !important; }
  .w-md-100 { width: 100% !important; }
}

/* LG breakpoint */
@media (min-width: 992px) {
  /* CUSTOM WIDTHS */
  .w-lg-auto { width: auto !important; }
  .w-lg-10 { width: 10% !important; }
  .w-lg-20 { width: 20% !important; }
  .w-lg-25 { width: 25% !important; }
  .w-lg-50 { width: 50% !important; }
  .w-lg-75 { width: 75% !important; }
  .w-lg-100 { width: 100% !important; }
}

/*------------------------------------------------------------------------*/
/* Question                                                               */
/*------------------------------------------------------------------------*/
.question-text p {
  font-family: $font-regular;
}

.question-description p {
  font-size: 90%;
}

/*------------------------------------------------------------------------*/
/* Map                                                                    */
/*------------------------------------------------------------------------*/
.container-fluid > #mapid {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

/*------------------------------------------------------------------------*/
/* Lightbox                                                               */
/*------------------------------------------------------------------------*/

.lightbox {

  background-color: black;

  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 100vw;
    }
  }

  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 100vw;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100vw;
      margin: 1.75rem auto;
    }
  }


  .modal-content,
  .lightbox-carousel,
  .carousel-inner {
    height: 94vh;

  }

  .carousel-item .ratio {
    height: calc(100vh - 3rem);
    // max-width: calc(100vw - 1rem);
    max-width: 100vw;
  }

  .modal-dialog {
    margin: 0;
  }

  .carousel-item {
    margin: 1rem 0;
  }

  .modal-xl {
    // max-width: 90vw !important;
  }

  .carousel-inner {
    background-color: #000 !important;
  }

  .lightbox-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 11;
  }

  .btn-close {
    margin-right: 1rem;
    transition: opacity 0.2s ease;

    svg {
      top: -7px !important;
      width: 1.5rem;
      height: 1.5rem;
    }
    &:hover {
      transition: opacity 0.2s ease;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: auto;
  z-index: 1000;
}
.modal-body .btn-close {
  // position: fixed !important;
}


.image-wrapper {
  overflow: hidden;
}



.image-fluid {
  transform: scaleY(1);
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: scale(1.1);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    transition: all 0.5s ease-in-out;
  }
}


/*------------------------------------------------------------------------*/
/* Links                                                                  */
/*------------------------------------------------------------------------*/

a {
  transition: all 0.2s ease-in-out;
  :hover {
    transition: all 0.2s ease-in-out;
  }
}

a[class^='color-'],
a[class*=' color-'] {
  &:hover {
    color: inherit;
    opacity: 0.8;
  }
}

a[data-toggle="lightbox"] {
  cursor: zoom-in;
}

a[href$=".pdf"] {
  display: inline-block;
  position: relative;
  margin-left: 30px;

  &:before {
    font-family: 'icomoon';
    color: $color-primary;
    content: "\f1c1";
    float: left;
    width: 1.4em;
    position: absolute;
    left: -30px;
  }
}

a.mf-link-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%20340%20340%27%3E%3Cpolygon%20points%3D%27229%2069.53%20207.79%2090.74%20272.05%20155%2010.53%20155%2010.53%20185%20272.05%20185%20207.79%20249.26%20229%20270.48%20329.47%20170%20229%2069.53%27%20style%3D%27fill%3A%2327763C%27%2F%3E%3C%2Fsvg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: -30px 5px;
  padding: 5px 0 5px 0;
  line-height: 1.8;
  color: $color-primary;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $color-primary;
    padding-left: 30px;
    transition: all 0.2s ease-in-out;
    background-position: 0 4px;
    text-decoration: none;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -2rem;
  visibility: hidden;
}

/*------------------------------------------------------------------------*/
/* Text Shadow                                                            */
/*------------------------------------------------------------------------*/

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

/*------------------------------------------------------------------------*/
/* Text Color Schemes                                                     */
/*------------------------------------------------------------------------*/

.teaser.text-normal {
  h4 {
    color: $color-primary;
  }

  .btn-primary {
    background-color: $color-primary;
    color: $color-white;

    &:hover {
      background-color: $color-primary-120;
    }
  }
}

.swiper-slide.text-light,
.teaser.text-light {
  h4, h4 a {
    color: $color-granny-smith-apple;
  }

  .mf-link-next {
    color: $color-white;
  }
  .btn-primary {
    background-color: $color-granny-smith-apple;
    color: $color-pine-tree;

    &:hover {
      background-color: $color-light-moss-green;
    }
  }
}

.carousel-item.text-normal {
  h1, h2 {
    color: $color-black;
  }

  .carousel-leadtext {
    color: $color-black;
  }

  .btn-primary {
    background-color: $color-primary;
    color: $color-white;

    &:hover {
      background-color: $color-primary-120;
    }
  }
}

.carousel-item.text-white,
.carousel-item.text-light {
  h1, h2 {
    color: $color-white;
  }

  .carousel-leadtext {
    color: $color-white;
  }

  .btn-primary {
    background-color: $color-granny-smith-apple;
    color: $color-pine-tree;

    &:hover {
      background-color: $color-light-moss-green;
    }
  }
}

/*------------------------------------------------------------------------*/
/* Height classes                                                         */
/*------------------------------------------------------------------------*/

.height__xss,
.height__xss .carousel-item img {
  height: 170px;
  @media (max-width: 991px) {
    height: 120px;
  }
  @media (max-width: 767px) {
    height: 120px;
  }
}

.height__xs,
.height__xs .carousel-item img {
  height: 300px;
  @media (max-width: 991px) {
    height: 250px;
  }
  @media (max-width: 767px) {
    height: 200px;
  }
}

.height__s,
.height__s .carousel-item img {
  height: 400px;
  @media (max-width: 991px) {
    height: 350px;
  }
  @media (max-width: 767px) {
    height: 300px;
  }
}

.height__m,
.height__auto,
.height__m .carousel-item img {
  height: 500px;
  @media (max-width: 991px) {
    height: 450px;
  }
  @media (max-width: 767px) {
    height: 400px;
  }
}

.height__l,
.height__l .carousel-item img {
  height: 600px;
  @media (max-width: 991px) {
    height: 520px;
  }
  @media (max-width: 767px) {
    height: 460px;
  }
}

.height__xl,
.height__xl .carousel-item img {
  height: 800px;
  @media (max-width: 991px) {
    height: 650px;
  }
  @media (max-width: 767px) {
    height: 500px;
  }
}

.height__50vh,
.height__50vh .carousel-item img {
  height: 50vh;
}

.height__75vh,
.height__75vh .carousel-item img {
  height: 75vh;
}

.height__100vh,
.height__100vh .carousel-item img {
  height: 100vh;
}


/*------------------------------------------------------------------------*/
/* Banner                                                                 */
/*------------------------------------------------------------------------*/

.banner-caption-wrapper {
  // max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

.banner-caption {
  // max-width: 1320px;
  position: absolute;
  overflow: hidden;

  // padding: 2rem;
  // bottom: initial;
  z-index: 2;

  // width: 800px;
  width: 100%;
  // max-width: 80%;

  @media (max-width: 991px) {
    // max-width: 70%;
  }
  @media (max-width: 767px) {
    // max-width: 80%;
  }
}








@media (max-width: 767px) {
  .banner-caption-wrapper,
  .banner-caption {
    max-width: calc(100vw - 32px);
  }
}

@media (min-width: 768px) {
  .banner-caption-wrapper,
  .banner-caption {
    max-width: 720px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (min-width: 992px) {
  .banner-caption-wrapper,
  .banner-caption {
    max-width: 960px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (min-width: 1200px) {
  .banner-caption-wrapper,
  .banner-caption {
    max-width: 1140px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (min-width: 1400px) {
  .banner-caption-wrapper,
  .banner-caption {
    max-width: 1320px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

/*------------------------------------------------------------------------*/
/* Overlay Caption                                                        */
/*------------------------------------------------------------------------*/

.overlay-caption {
  position: absolute;
  right: 15%;
  // bottom: 1.25rem;
  left: 15%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
  text-align: center;
  width: 80%;
}

/*------------------------------------------------------------------------*/
/* Alignment classes                                                      */
/*------------------------------------------------------------------------*/

/*
.alignment__bottom-right {
  bottom: 10%;
  right: 10%;
  text-align: right;
  left: auto;
}

.alignment__top-left {
  top: 10%;
  left: 10%;
  text-align: left;
  right: auto;
}


.carousel-caption.alignment__center-center,
.carousel-caption.alignment__bottom-right,
.carousel-caption.alignment__bottom-left,
.carousel-caption.alignment__top-right,
.carousel-caption.alignment__top-left {
  width: 800px;
  max-width: 80%;

  @media (max-width: 991px) {
    max-width: 70%;
  }
  @media (max-width: 767px) {
    max-width: 80%;
  }
}



.carousel-caption.alignment__bottom-right {
  bottom: 10%;
  right: 10%;
  text-align: right;
  left: auto;

  @media (max-width: 767px) {
    bottom: 10px;
  }
}



.carousel-caption.alignment__top-right,
.video-caption.alignment__top-right {
  top: 10%;
  right: 10%;
  text-align: right;
  left: auto;
}
*/



/*
.banner-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
*/

/*
.banner-caption {
  overflow: hidden;
  padding: 2rem;
  bottom: initial;
  z-index: 2;

  width: 800px;
  max-width: 80%;

  @media (max-width: 991px) {
    max-width: 70%;
  }
  @media (max-width: 767px) {
    max-width: 80%;
  }
}
*/


.alignment__top-left,
.alignment__center-left,
.alignment__bottom-left,
.alignment__top-right,
.alignment__center-right,
.alignment__bottom-right,
.alignment__center-center,
.alignment__bottom-center {
}

.alignment__top-left {
  top: 10%;
  // left: 0;
  text-align: left;
  right: auto;
}

.alignment__center-left {
  text-align: left;
  top: 50%;
  // left: 0;
  transform: translateY(-50%) !important;
}

.alignment__bottom-left {
  bottom: 10%;
  // left: 0;
  text-align: left;

  @media (max-width: 767px) {
    bottom: 90px;
  }
}

.alignment__top-right {
  top: 10%;
  // right: 0;
  text-align: right;
  left: auto;
}

.alignment__center-right {
  text-align: right;
  top: 50%;
  // right: 0;
  transform: translateY(-50%) !important;
}

.alignment__bottom-right {
  bottom: 10%;
  // right: 0;
  text-align: right;

  @media (max-width: 767px) {
    bottom: 90px;
  }
}

.alignment__center-center {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.alignment__bottom-center {
  text-align: center;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) !important;

  @media (max-width: 767px) {
    bottom: 90px;
  }
}

/*

.carousel-leadtext {
  font-size: 2rem;
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
  line-height: 1.2;
}


*/


.banner-caption-flex {
  display: flex;
  flex-direction: row;
}

.banner-caption-marker {
  padding-top: 0.25rem;
  padding-right: 1.5rem;

  @media (max-width: 991px) {
    padding-right: 1rem;
  }

  @media (max-width: 767px) {
    padding-right: 0.5rem;
  }
}

.alignment__top-right,
.alignment__center-right,
.alignment__bottom-right {
  > .banner-caption-flex {
    // justify-content: flex-end;
    flex-direction: row-reverse;

    > .banner-caption-marker {
      padding-right: 0;
      padding-left: 1.5rem;

      @media (max-width: 991px) {
        padding-left: 1rem;
      }

      @media (max-width: 767px) {
        padding-left: 0.5rem;
      }
    }
  }
}

img.banner-caption-marker {
  height: 150px !important;
  max-width: 120px;

  @media (max-width: 991px) {
    height: 125px !important;
    max-width: 100px;
  }

  @media (max-width: 767px) {
    height: 100px !important;
    max-width: 72px;
  }
}


/*------------------------------------------------------------------------*/
/* Fonts                                                                  */
/*------------------------------------------------------------------------*/

h1 {
  font-family: $font-condensed;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.1 !important;
  color: $color-secondary;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 767px) {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
  }

  a {
    color: $color-primary;
    text-decoration: none;
  }
}

h2 {
  font-family: $font-condensed;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.1 !important;
  color: var(--color-primary);
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size: 2.5rem;
    margin-top: 1.25rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 767px) {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }

  a {
    color: $color-primary;
    text-decoration: none;
  }
}


h3 {
  font-family: $font-condensed;
  font-weight: 400;
  font-size: 2.5rem;
  color: $color-primary;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
  @media (max-width: 767px) {
    font-size: 2rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }

  a {
    color: $color-primary;
    text-decoration: none;
  }
}

h4 {
  font-family: $font-condensed;
  font-weight: 400;
  font-size: 1.75rem;
  color: $color-primary;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 1.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }

  a {
    color: $color-primary;
    text-decoration: none;
  }
}

.mf-leadtext {
  font-size: 1.5rem;

  @media (max-width: 767px) {
    font-size: 1.375rem;
  }
}

.text-body {
  font-size: 20px;
}

.news-date {
  font-size: 1rem;
}

.paragraph {
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

}

/*------------------------------------------------------------------------*/
/* 5-column grid                                                          */
/*------------------------------------------------------------------------*/

.col-xs-2-4,
.col-sm-2-4,
.col-md-2-4,
.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-2-4 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-2-4 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-2-4 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-2-4 {
    width: 20%;
    float: left;
  }
}

/*------------------------------------------------------------------------*/
/* 8-column grid                                                          */
/*------------------------------------------------------------------------*/

.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-1-5 {
  width: 12.5%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 12.5%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-1-5 {
    width: 12.5%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 12.5%;
    float: left;
  }
}



.text-paragraph--table {
  width: 100%;
  overflow: scroll;
}

/*------------------------------------------------------------------------*/
/* Tables                                                                 */
/*------------------------------------------------------------------------*/

/*
.text-paragraph {
  table {
    width: 100%;
    margin: 1rem 0;
    border-collapse: collapse;
    overflow: scroll;

    overflow-x: auto;
    display: block;
    width: fit-content;
    max-width: 100%;
  }
  table, th, td {
    border: 1px solid gray;
  }
  th, td {
    padding: 0.25rem 0.5rem;
  }
}
*/

.text-paragraph table {
  width: 100%;
  margin: 1rem 0;
  border-collapse: collapse;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  width: fit-content;
  // min-width: 350px;
  max-width: 100%;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);

  th, td {
    padding: 0.6rem 1rem;
  }
  tr {
    border-bottom: 1px solid #dddddd;
  }

  td {
    border-right: 1px solid #dddddd;
  }

  td:last-child {
    border-right: none;
  }

  tr:nth-of-type(even) {
    // background-color: #f3f3f3;
  }

  tr:last-of-type {
    // border-bottom: 2px solid $color-primary;
  }
}

.text-paragraph figure img {
  width: 100%;
}

/*------------------------------------------------------------------------*/
/* Images                                                                 */
/*------------------------------------------------------------------------*/

.mf-image--rounded,
.mf-section--rounded {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  overflow: hidden;
}

.mf-image--rounded-small,
.mf-section--rounded-small {
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  overflow: hidden;
}

/*------------------------------------------------------------------------*/
/* Button                                                                 */
/*------------------------------------------------------------------------*/

.mf-button-row {
  margin: 0 -10px 0 -10px;

  a {
    margin: 0 10px 0 10px;
  }
}

.btn {
  font-size: 1.25rem;
  min-width: 100px;
  border-radius: 25px;
  padding: 8px 1.5rem 10px;
}

.btn-primary {
  border: none;
  background-color: var(--color-primary);
  color: $color-white;

  &:hover {
    background-color: var(--color-primary-120);
    color: $color-white;
  }

  &:active,
  &:focus,
  &:active:focus {
    color: $color-white;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: 0 0 0 0.25rem var(--color-primary-40);
  }

  &:disabled {
    color: var(--color-primary-20);
    background-color: var(--color-primary-60);
  }
}

.btn-outline-primary {
  border: 1px solid var(--color-primary);
  background-color: transparent;
  color: var(--color-primary);

  &:hover {
    color: $color-white;
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
  }

  &:active,
  &:focus,
  &:active:focus {
    color: $color-white;
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: 0 0 0 0.25rem var(--color-primary-40);
  }
}





.btn-secondary {
  border: none;
  background-color: $color-secondary;
  color: $color-white;

  &:hover {
    background-color: $color-secondary-120;
  }
}


/*
div.text-body {
  width: 100%;
  overflow: scroll;
}
*/


.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
   */
}

.image-bg {
  background-position: center;
  background-size: cover;
  position: relative;
}

.image-negative-offset {
  z-index: -1;
  margin-top: -50px;
  margin-bottom: -50px;
}

@supports (-webkit-overflow-scrolling: touch) {
  .parallax {
    background-attachment: initial;
  }
}

.foobar99 {
  font-size: 100px;
}

figcaption {
  font-size: 1.125rem;
  text-align: center;
  margin-top: 0.5rem;
}

.bg-overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*------------------------------------------------------------------------*/
/* Teaser                                                                 */
/*------------------------------------------------------------------------*/

.teaser .icon,
.teaser .icon-l,
.teaser .icon-bg {
  margin: 1rem;
}

.teaser .icon-bg {
  padding: 1.25rem;
  border-radius: 50%;
  color: #fff;
}

.teaser img {
  width: 100%;
}

.text-start .icon,
.text-start .icon-l,
.text-start .icon-bg {
  margin-left: 1rem;
  margin-right: auto;
}

.text-center .icon,
.text-center .icon-l,
.text-center .icon-bg {
  margin-left: auto;
  margin-right: auto;
}

.text-end .icon,
.text-end .icon-l,
.text-end .icon-bg {
  margin-left: auto;
  margin-right: 1rem;
}

.card.teaser {
  // margin-bottom: 1.5rem;
  border: none;
}

.card {
  background-color: transparent;
  border: none;
}

.card-body {
  padding: 1rem 0 0;
}

.card-body--text-overlay {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}

.card-body .card-title {
  // color: $color-text;
  font-family: $font-condensed;
  font-weight: 700;
  // color: $color-primary;
  font-size: 1.75rem;
  text-transform: uppercase;
}




.social a {
  background-color: transparent;
  transition: background-color 300ms linear;

}

.social a:hover {
  background-color: #AD310B;
}

.social a.facebook:hover {
  background-color: #4668B3;
}

.social a.twitter:hover {
  background-color: #2FBBED;
}

.social a.linkedin:hover {
  background-color: #0169aa;
}

.social a.instagram:hover {
  background-color: #AD310B;
}


/*
.social a {
  background-color: transparent;
  transition: background-color 1000ms linear;
}

.social a.instagram:hover {
  background-image: linear-gradient(#972dc0, #fed46c);
  transition: background-color 1000ms linear;
}
*/

/*
.social a:hover i {
  height: 100%;
}

.social a {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  transition: all 200ms ease-out;
}
*/

/*------------------------------------------------------------------------*/
/* Swiper https://swiperjs.com/                                           */
/*------------------------------------------------------------------------*/

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
  // padding-bottom: 2.5rem !important;
}

// https://www.urlencoder.org/
// <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 340 340'><polygon points='229 69.53 207.79 90.74 272.05 155 10.53 155 10.53 185 272.05 185 207.79 249.26 229 270.48 329.47 170 229 69.53' style='fill:#cccccc'/></svg>
/*
.swiper-button-next {
  text-align: center;
  font-size: 36px;
  position: absolute;
  bottom: 0;
  top: auto;
  right: 0;
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%20340%20340%27%3E%3Cpolygon%20points%3D%27229%2069.53%20207.79%2090.74%20272.05%20155%2010.53%20155%2010.53%20185%20272.05%20185%20207.79%20249.26%20229%20270.48%20329.47%20170%20229%2069.53%27%20style%3D%27fill%3A%2327763C%27%2F%3E%3C%2Fsvg%3E") !important;
  &::after {
    content: '';
  }
}
*/

div[class^="swiper-button-next-id-"],
div[class*=" swiper-button-next-id-"],
.swiper-button-next,
div[class^="swiper-button-prev-id-"],
div[class*=" swiper-button-prev-id-"],
.swiper-button-prev {
  position: absolute;
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    font-family: swiper-icons;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
  }

  &.swiper-button-disabled {
    opacity: 0.4;
  }
}

div[class^="swiper-button-next-id-"],
div[class*=" swiper-button-next-id-"],
.swiper-button-next {
  color: #fff;
  text-shadow: 1px 1px 1px #000000;
  top: auto;
  // bottom: 0;
  bottom: calc(50% - 24px);
  right: 8px;

  &::after {
    // font-size: 24px;
    // line-height: 24px;
    content: 'next';
    font-size: 40px;
    line-height: 40px;
  }
}


// https://www.urlencoder.org/
// <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 340 340'><polygon points='329.47 155 67.95 155 132.21 90.74 111 69.53 10.53 170 111 270.48 132.21 249.26 67.95 185 329.47 185 329.47 155' style='fill:#cccccc'/></svg>
/*
.swiper-button-prev {
  text-align: center;
  font-size: 36px;
  position: absolute;
  left: 0;
  bottom: 0;
  top: auto;
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%20340%20340%27%3E%3Cpolygon%20points%3D%27329.47%20155%2067.95%20155%20132.21%2090.74%20111%2069.53%2010.53%20170%20111%20270.48%20132.21%20249.26%2067.95%20185%20329.47%20185%20329.47%20155%27%20style%3D%27fill%3A%2327763C%27%2F%3E%3C%2Fsvg%3E") !important;
  &::after {
    content: '';
  }
}
*/

[class^="swiper-button-prev-id-"],
[class*=" swiper-button-prev-id-"],
.swiper-button-prev {
  color: #fff;
  text-shadow: 1px 1px 1px #000000;
  top: auto;
  // bottom: 0;
  bottom: calc(50% - 24px);
  left: 8px;

  &::after {
    // font-size: 24px;
    // line-height: 24px;
    content: 'previous';
    font-size: 40px;
    line-height: 40px;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
  line-height: 1;
}

[class^="swiper-pagination-id-"],
[class*=" swiper-pagination-id-"] {
  position: absolute;
  text-align: center;
  transition: .3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}


.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}


.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}


.swiper.text-light {

  .swiper-button-prev {
    color: $color-granny-smith-apple;
  }

  .swiper-button-next {
    color: $color-granny-smith-apple;
  }

  .swiper-pagination-bullet {
    background-color: $color-primary-20;
  }

  .swiper-pagination-bullet-active {
    background-color: $color-granny-smith-apple;
  }

}

.swiper-pagination-bullet-active {
  background-color: $color-primary;
}

.swiper-button-disabled {
  display:none;
}

/*------------------------------------------------------------------------*/
/* Carousel                                                               */
/*------------------------------------------------------------------------*/

.carousel {
  overflow: hidden;
  isolation: isolate;
}

.carousel .carousel-item img {
  object-fit: cover;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.carousel-control-prev-icon {
  margin-left: 1vw;
}

.carousel-control-next-icon {
  margin-right: 1vw;
}

/*
.carousel {
  height: $mf-carousel-height;

  .carousel-item img {
    height: $mf-carousel-height;
    object-fit: cover;
  }
}
*/

.carousel-inner {
}

.image-shade:before,
.carousel-item.shade:before {
  content: "";
  background-color: rgba(4, 12, 21, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#banner .carousel-item:before {
  content: "";
  // height:20px;width:100%;position:absolute;left:0; top:0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
}

#banner .carousel .carousel-item img {
  width: 100vw;
}


/* Classes implemented as Section Height categories */

/*
.carousel.height__600px,
.carousel.height__600px .carousel-item img {
  height: 600px;
}

.carousel.height__50vh,
.carousel.height__50vh .carousel-item img {
  height: 50vh;
}

.carousel.height__75vh,
.carousel.height__75vh .carousel-item img {
  height: 75vh;
}

.carousel.height__100vh,
.carousel.height__100vh .carousel-item img {
  height: 100vh;
}
*/

/*
.carousel-caption {
  width: 40%;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  text-align: left;
}
*/
// Bottom Left
/*
.carousel-caption {
  width: 40%;
  bottom: 15%;
  transform: translateY(15%);
  position: absolute;
  left: 15%;
  text-align: left;
}
*/


/*
.carousel-caption {
  width: 40%;
  bottom: 15%;
  transform: translateY(15%);
  position: absolute;
  right: 15%;
  text-align: right;
}
*/


/*------------------------------------------------------------------------*/
/* Search                                                                 */
/*------------------------------------------------------------------------*/
.searchresult {
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
}

.searchresult:first-of-type{
  border-top: 1px solid #ddd;
}

/*------------------------------------------------------------------------*/
/* Video                                                                  */
/*------------------------------------------------------------------------*/

.video-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.video-container.shade:before {
  content: "";
  background-color: rgba(4, 12, 21, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.video-container:before {
  content: "";
  // background-color: rgba(4, 12, 21, 0.5);
  // background-color: rgba(255, 255, 255, 1);
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}


.video-container video {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // z-index: 101;
}

.video-caption {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 1002;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 80px);
  width: 100%;
}

.video-caption-bg {
  background-color: rgba(33, 33, 33, 0.4);
  padding: 2rem;
  max-width: 60%;
}

.video-leadtext {
  font-size: 2rem;
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
  line-height: 1.2;
}

.video-caption.bottom-left {
  position: absolute;
  display: block;
  height: auto;
  bottom: 20px;
  left: 20px;

  @media (max-width: 767px) {
    bottom: 10px;
  }
}

.video-caption.bottom-right {
  position: absolute;
  display: block;
  height: auto;
  bottom: 20px;
  right: 20px;

  @media (max-width: 767px) {
    bottom: 10px;
  }
}

.video-caption.bottom-right,
.video-caption.bottom-left,
.video-caption.top-right,
.video-caption.top-left {
  max-width: 60%;
}

.video-caption.bottom-right {
  bottom: 15%;
  right: 15%;
  text-align: right;
  left: auto;

  @media (max-width: 767px) {
    bottom: 10px;
  }
}

.video-caption.bottom-left {
  bottom: 15%;
  left: 15%;
  text-align: left;
  right: auto;

  @media (max-width: 767px) {
    bottom: 10px;
  }
}

.video-caption.top-right {
  top: 15%;
  right: 15%;
  text-align: right;
  left: auto;
}

.video-caption.top-left {
  top: 15%;
  left: 15%;
  text-align: left;
  right: auto;
}

//---------------------------------------------
//
//---------------------------------------------










/*

.ud-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
*/
/*
.sticky .navbar {
  padding: 0;
}
*/

/*
.navbar {
  padding: 0px 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
*/
/*
.navbar-brand {
  padding: 0;
}
*/


/*
@media (min-width: 992px) {
  .dropdown-menu .dropdown-toggle:after {
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
  }
  .dropdown-menu .dropdown-menu{
    margin-left:0; margin-right: 0;
  }
  .dropdown-menu li{
    position: relative;
  }
  .nav-item .submenu{
    display: none;
    position: absolute;
    left: 100%;
  }
  .nav-item .submenu-left{
    right:100%; left:auto;
  }

}
*/


.scrolled-down{
  transform:translateY(-100%); transition: all 0.3s ease-in-out;
}
.scrolled-up{
  transform:translateY(0); transition: all 0.3s ease-in-out;
}



/*------------------------------------------------------------------------*/
/* Pricing                                                                */
/*------------------------------------------------------------------------*/

.mf-pricing-table {
  box-shadow: 0 0 9px 1px rgba(0,0,0,.1);
}

.mf-pricing-table--header {
  padding: 0.5rem 0.25rem;
  background-color: var(--color-primary);
  color: var(--color-white);

  h4 {
    color: var(--color-white);
  }
}

.mf-pricing-table--price {
  background-color: var(--color-primary-light);
  color: var(--color-white);
  font-size: 2.5rem;
}

.mf-pricing-table--title {

}

.mf-pricing-table--subtitle {

}

ul.mf-pricing-table--items {
  border: 1px solid var(--color-gray-200);
  margin-bottom: 0;
}

li.mf-pricing-table--item {
  padding: 0.5rem 0.25rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-gray-200);
  }

  &:nth-child(2n) {
    background-color: var(--color-gray-100);
  }

  p {
    margin: 0;
  }
}

/*------------------------------------------------------------------------*/
/* Feature                                                                */
/*------------------------------------------------------------------------*/

.mf-section-feature {
  h2 {
    color: $color-primary;
  }
  p {
    color: $color-text;
  }
}

.mf-feature-entries {

}

.mf-feature-content--box_right {
  background-color: transparent;
  // min-height: 450px;
  padding: 50px 60px 50px 100px;
}

.mf-feature-content--box_left {
  background-color: transparent;
  // min-height: 450px;
  padding: 50px 100px 50px 60px;
}

.mf-feature--image_right {
  margin-right: -40px;
}

.mf-feature--image_left {
  margin-left: -40px;
}

@media (max-width: 767px) {
  .mf-section-feature {
    h2 {
      font-size: 2rem;
    }
  }

  .mf-feature-content--box_right {
    margin-top: -100px;
    min-height: auto;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 80px 30px 30px 30px;
  }

  .mf-feature--image_right {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mf-feature-content--box_left {
    margin-top: -50px;
    min-height: auto;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 80px 30px 30px 30px;
  }

  .mf-feature--image_left {
    margin-left: 30px;
    margin-right: 30px;
  }

}

ul.mf-feature-entries {
  list-style-type: none;
  padding-left: 0;

  li {
    a {
      text-decoration: none;
    }
    &:before {
      font-family: 'icomoon';
      color: $color-primary;
      content: "\f0a9";
      float: left;
      width: 1.4em;
    }
  }

}

/*------------------------------------------------------------------------*/
/* Breadcrumbs                                                                 */
/*------------------------------------------------------------------------*/

.breadcrumbs {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a.breadcrumb--root,
  a.breadcrumb--parent,
  a.breadcrumb--current,
  span.breadcrumb--current {
    font-size: 1.5rem;

    @media (max-width: 991px) {
      font-size: 1.375rem;
    }
    @media (max-width: 767px) {
      font-size: 1.25rem;
    }

    text-decoration: none;
    font-weight: bold;
    margin-right: 2rem;
    display: flex;

    &:before {
      font-family: 'icomoon';
      color: $color-primary;
      content: "\f0a9";
      width: 1.4em;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      width: 1.5rem;

      @media (max-width: 991px) {
        width: 1.375rem;
      }
      @media (max-width: 767px) {
        width: 1.25rem;
      }
    }
  }

  a.breadcrumb--root {
    &:before {
      content: "\f0aa";
    }
  }

  a.breadcrumb--parent {
    &:before {
      content: "\f0aa";
    }
  }

  a.breadcrumb--current,
  span.breadcrumb--current {
    color: $color-secondary;
    &:before {
      color: $color-secondary;
      content: "\f0a9";
    }
  }
}





/*------------------------------------------------------------------------*/
/* Footer                                                                 */
/*------------------------------------------------------------------------*/

.mf-icon-before {
  vertical-align: middle;
  margin-right: 0.5rem;
}

footer {
  background-color: $color-primary;

  img {
    max-width: 100%;
  }

  nav ul {
    list-style: none;
    padding-left: 0;
  }

  nav ul a {
    font-weight: 400;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
  }

  nav ul a:hover {
    color: var(--color-primary-300);
  }

  .mf-footer-company {
    color: $color-white;
  }

  .mf-footer-company-description {
    color: $color-white;

    p {
      margin: 0;
      line-height: 1.2;
    }
  }

  .mf-footer-copyright {
    color: $color-white;
    margin-right: 2rem;
    font-size: 1rem;
  }

  .mf-footer-address {
    color: $color-white;
  }

  .mf-footer-phone,
  .mf-footer-phone a[href^="tel"] {
    color: $color-white !important;
  }

  .mf-footer-email,
  .mf-footer-email a {
    color: $color-white;
  }

  .mf-footer-label {
    font-size: 1.25rem;
    color: $color-white;
  }

  ul.mf-footer--additional-links {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-size: 1rem;
    text-decoration: none;

    li {
      display: inline-block;
      margin-right: 0.5rem;
    }
    li + li {

      &::before {
        content: '|';
        margin-right: 0.5rem;
        color: $color-white;
      }
    }
  }

  .mf-footer--social-link {
    color: $color-white;
    margin: 0 4px;
  }
}


/*------------------------------------------------------------------------*/
/* Modal                                                                  */
/*------------------------------------------------------------------------*/

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-header .modal-close {
  background-color: initial;
  border: 0;
}

.modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-content {
  .carousel-inner {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/*------------------------------------------------------------------------*/
/* Accordion                                                              */
/*------------------------------------------------------------------------*/

.accordion-button {
  font-size: 1.5rem;
  color: $color-primary;

  > div {
    padding-right: 20px;
  }
}

.accordion {
  h2 {
    margin-top: 0;
  }
  figure img {
    max-width: 100%;
  }
}

// Custom Styles

.accordion-button,
.accordion-button:not(.collapsed) {
  // background-color: var(--color-primary);
  // color: var(--color-white);
  color: var(--color-primary);
  border-top: 1px solid var(--color-primary);
  background-color: var(--color-white);
  box-shadow: none;

  .icon {
    // color: var(--color-white) !important;
    color: var(--color-primary) !important;
  }

  &::after {
    // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23C89111'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }

  &:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(204,204,204,0.25);
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}


.accordion-item {
  border: none;
  margin-bottom: 2px;

  &:first-of-type .accordion-button.collapsed {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }


  &:last-child {
    border-bottom: 1px solid var(--color-primary);
  }

}

/*
.accordion-button:not(.collapsed) {
  color: #236a36;
  background-color: #e9f1ec;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,0.125);
}

.accordion-header {
  background-color: var(--color-primary);
}

.accordion-item {
  background-color: var(--color-primary);
  border: none;
}

 */