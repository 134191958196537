$white: #fff;
$black: #000;


$color-white: #fff;
$color-black: #000;
$color-text: #20370C;

$color-primary: #C89111;
$color-japanese-laurel: $color-primary;

$color-primary-20: #D7E3D9;
$color-gainsboro: $color-primary-20;

$color-primary-40: #AFC7B3;
$color-ash-gray: $color-primary-40;

$color-primary-60: #87AB8D;
$color-morning-blue: $color-primary-60;

$color-primary-80: #529163;
$color-middle-green: $color-primary-80;

$color-primary-120: #1F5E30;
$color-mughal-green: $color-primary-120;

$color-granny-smith-apple: #ADD491;

$color-pine-tree: #20370C;

$color-light-moss-green: #C3DBAC;

$color-nyanza: #E0F8CF;


$color-secondary: #AC312E;
$color-secondary-120: #7F2E29;

.bg-primary {
  background-color: $color-primary;
}

.bg-primary-20 {
  background-color: $color-primary-20;
}

.bg-lightgreen {
  background-color: #E0F8CF;
}

.bg-secondary {
  background-color: $color-secondary;
}

// https://maketintsandshades.com/

:root {

  --color-primary-1900: #140e02;
  --color-primary-1800: #281d03;
  --color-primary-1700: #3c2b05;
  --color-primary-1600: #503a07;
  --color-primary-1500: #644909;
  --color-primary-1400: #78570a;
  --color-primary-1300: #8c660c;
  --color-primary-1200: #a0740e;
  --color-primary-1100: #b4830f;

  --color-primary: #C89111;

  --color-primary-900: #ce9c29;
  --color-primary-800: #d3a741;
  --color-primary-700: #d9b258;
  --color-primary-600: #debd70;
  --color-primary-500: #e4c888;
  --color-primary-400: #e9d3a0;
  --color-primary-300: #efdeb8;
  --color-primary-200: #f4e9cf;
  --color-primary-100: #faf4e7;


  --color-primary-dark: #7a1e11;
  --color-primary-light: #af3f31;
  --color-primary-background: #fadfdb;

  --color-white: #fff;
  --color-black: #000;

  --color-primary-80: #af3f31;
  --color-grey-20: #ebebeb;

  --color-gray-100: #f8f9fa;
  --color-gray-200: #e9ecef;
  --color-gray-300: #dee2e6;
  --color-gray-400: #ced4da;
  --color-gray-500: #adb5bd;
  --color-gray-600: #6c757d;
  --color-gray-700: #495057;
  --color-gray-800: #343a40;
  --color-gray-900: #212529;
}

body {
  --border-default: 1px solid var(--color-gray-300);
}
